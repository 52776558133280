import styled, { keyframes } from "styled-components";
import { colors, scoreToColor } from "colors";

export const DealPageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  @media screen and (min-width: 1470px) {
    justify-content: flex-start;
  }
  .upload {
    height: 100%;
    width: 100%;
    font-size: 40px;
    text-align: center;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 150px;
    }
    svg {
      height: 200px;
      width: 200px;
      margin: 0 auto;
      path {
        fill: ${colors.mainGreen};
      }
    }
  }
  .left-wrapper {
    flex-wrap: nowrap;
    align-items: flex-end;
    .total-score-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .floating-wrapper-b {
      border-bottom: none;
      position: relative;
      margin-bottom: -1px;
      z-index: 100;
      border-radius: 3px 3px 0px 0px;
      background-color: ${colors.darkGray2};
      box-shadow: -1px -9px 20px 0px #0000001a;
    }
    .floating-wrapper-a {
      position: relative;
      z-index: 99;
      padding-bottom: 20px;
      width: 1050px;
      overflow: visible;
      box-shadow: 0px 20px 45px 0px #0000001a, 0px 82px 82px 0px #00000017,
        0px 184px 110px 0px #0000000d, 0px 327px 131px 0px #00000003,
        0px 511px 143px 0px #00000000;
      border: 1px solid ${colors.deepGray} !important;
      ::-webkit-scrollbar {
        height: 14px;
        width: 14px;
        background: ${colors.middleGray};
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.black};
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      ::-webkit-scrollbar-corner {
        background: #000;
      }

      ::-webkit-scrollbar-track {
        height: 100px; /* Fixed height of the scrollbar track */
      }
      .white-underline {
        color: ${colors.white};
        text-decoration: underline;
        text-align: left;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .coach-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: ${colors.mainGreen};
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .confirm-oppty {
    width: 500px;
    height: 300px;
    background-color: ${colors.darkGray};
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    h2 {
      text-align: center;
      color: ${colors.mainGreen};
    }
    button {
      margin: 10px;
      color: ${colors.darkGray};
      background-color: ${colors.mainGreen};
      border: none;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      font-weight: 600;
    }
  }
`;

export const Deal = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  margin-left: 0;
  height: 83%;
`;

export const TopRow = styled.div`
  width: 100%;
`;

export const Feedback = styled.div``;

export const DealHeader = styled.div`
  width: 100%;
`;

export const CategoryWrapper = styled.div`
  position: relative;
  text-align: left;
  height: auto;
  padding: 15px 30px;
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    margin-top: 30px;
  }
`;

export const Category = styled.div`
  .flex-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-left: 20px;
    width: 100% !important;
    p {
      margin: 0;
    }
  }
`;

export const CheckboxLabel = styled.label`
  /* Style the label to mimic the appearance of a checkbox */
  display: inline-block;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 4px;
  background: linear-gradient(120deg, #2f2f2f 0%, #4b4d52 100%);
  cursor: pointer;
  svg {
    display: none;
  }
`;

export const CheckboxInput = styled.input`
  /* Hide the default checkbox */
  display: none;
  &:checked + ${CheckboxLabel} {
    background: none;
    transform: scale(1.2);
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
`;

export const AddCallWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const AddCallModal = styled.div`
  height: 90vh;
  position: relative;
  width: 50%;
  margin: 0 auto;
  background-color: #34363c;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.button<TabProps>`
  height: 35px;
  padding: 6.5px;
  border-radius: 5px;
  gap: 6.65px;
  width: 160px;
  background-color: ${colors.darkGray2};
  border: none;
  text-align: center;
  cursor: pointer;
  box-shadow: ${colors.boxShadow4};
  color: ${colors.white2};
  font-size: 15px;
  &:hover {
    background-color: ${colors.offWhite};
    color: ${colors.black};
  }
  ${(props) =>
    props.active &&
    `
      background-color: ${colors.offWhite};
      color: ${colors.black};
      font-weight: 700;
    `}
`;

export const TabsWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
  hr {
    width: 26px;
    transform: rotate(90deg);
    border: none;
    height: 1px;
    background-color: ${colors.grayWhite};
    margin: 0px 0px;
  }
`;

interface ScoreBoxProps {
  score: number;
}

export const ScoreBox = styled.div<ScoreBoxProps>`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  color: ${colors.white2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  font-weight: 700;
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background-color: ${background};
      color: ${text};
    `;
  }};
  .floating-advice {
    width: 330px;
    min-height: 286px;
    height: fit-content;
    background-color: white;
    display: none;
    position: absolute;
    top: 47px;
    left: 27%;
    transform: translate(-50%, 0);
    z-index: 100;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 80px;
    z-index: 1000;
    p {
      width: 100%;
      text-align: left;
      font-weight: 400;
    }
    .triangle {
      height: 38px;
      width: 20px;
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      background: white;
    }
    button {
      width: 90%;
      height: 60px;
      background-color: ${colors.black};
      border: none;
    }
  }
  &:hover {
    .floating-advice {
      display: block;
    }
  }
`;

export const FloatingBox = styled.div`
  width: 125px;
  height: 125px;
  position: relative;
  z-index: 98;
  padding: 10px;
  gap: 0px;
  border-radius: 5px;
  box-shadow: 0px 3px 7px 0px #0000001a, 0px 13px 13px 0px #00000017,
    0px 30px 18px 0px #0000000d, 0px 54px 22px 0px #00000003,
    0px 84px 24px 0px #00000000;
  p {
    margin-top: 35px;
    text-align: center;
  }
  .title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    h4 {
      font-size: 12px;
    }
  }
  .svg-wrapper {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    background-color: ${colors.black};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
    }
  }
`;

export const WidgetsWrapper = styled.div`
  width: 327px;
  height: 696px;
  min-width: 327px;
  padding: 20px;
  margin-right: 30px;
`;

export const ScoreBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 35px 0;
  max-width: 1375px;
  p {
    text-align: center;
    font-size: 12px;
  }
  .score-circle-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    p {
      width: 100%;
    }
  }
`;

interface CircleScoreProps {
  score: number | undefined;
  index: number;
}

const scaleUpDown = keyframes`
  0%, 100% {
    transform: scale(1); /* Start and end with original scale */
  }
  50% {
    transform: scale(1.1); /* Scale to 120% in the middle */
  }
`;

export const CircleScore = styled.div<CircleScoreProps>`
  border-radius: 100%;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white2};
  span {
    font-size: 8px;
    margin-left: 2px;
  }
  ${(props) => {
    if (props.score !== undefined) {
      let { background } = scoreToColor(props.score);
      return `
        border: 6px solid ${background};
      `;
    }
  }};
  animation: ${scaleUpDown} 0.6s ease-in-out forwards;
  animation-delay: ${(props) => props.index * 0.1}s;
`;

export const OpportunityWrapper = styled.div`
  position: relative;
  margin-right: 50px;
`;

export const OpportunitySearch = styled.input`
  margin-bottom: 10px;
  width: 100%;
`;

export const OpportunityList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const OpportunityListUl = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OpportunityListItem = styled.li<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "#e0e0e0" : "transparent"};
  border-bottom: 1px solid #eee;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SelectedOpportunity = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #888;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white2};
  .opportunity-section {
    margin-right: 40px;
  }
  .hubspot-deal-section {
    margin-right: 40px;
  }
  .hubspot-deal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    color: ${colors.mainGreen};
    text-decoration: none;
    margin-bottom: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    color: ${colors.offWhite};
    padding-left: 10px;
    margin-top: 10px;
    max-width: 490px;
    width: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limits the text to 2 lines
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; // Adds the ellipsis at the end of the text
    word-wrap: break-word;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    h4 {
      margin-right: 30px;
    }
  }
  .share {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    border-radius: 20px;
    padding: 7px 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
  svg {
    margin-left: 7px;
    width: 20px;
    height: 16px;
    path {
      fill: ${colors.white2};
    }
  }
  .deal-name-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    input {
      font-size: 32px;
      color: ${colors.offWhite};
      background-color: transparent;
      border: 1px solid ${colors.offWhite};
    }
    .checkmark {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.mainGreen};
      border-radius: 5px;
      margin-left: 15px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  .call-wrapper {
    width: 100%;
    margin-bottom: 40px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    select {
      width: 100%;
      max-width: 100%;
    }
    p {
      margin-top: 8px;
      margin-left: 3px;
      font-size: 11px;
    }
  }
  button {
    width: 100%;
    height: 30px;
    background-color: ${colors.middleGray};
    border: none;
    color: ${colors.offWhite};
    text-align: center;
    padding: 8px 0;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      color: ${colors.black};
      background-color: ${colors.offWhite};
    }
  }
  .opportunity-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .checkmark {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.mainGreen};
    border-radius: 5px;
    margin-left: 15px;
    cursor: pointer;
  }
  .opportunity-name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; // Limits the text to 2 lines
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; // Adds the ellipsis at the end of the text
    word-wrap: break-word;
  }
`;

const strikeAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const strike = keyframes`
  from {
    text-decoration-color: transparent;
  }
  to {
    text-decoration-color: auto;
  }
`;

export const StrikeThroughText = styled.div`
  position: relative;
  display: inline-block;
  text-decoration: line-through;
  color: black; /* Adjust color as needed */
  line-height: 24px;
  animation: ${strike} 1s forwards;

  /* &:after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    height: 2px; /* Adjust thickness as needed */
  /* background-color: black; /* Adjust color as needed */
  /* animation: ${strikeAnimation} 1s forwards;
  } */
`;

const skeletonLoading = keyframes`
0% {
  background-position: -1800px 0;
}
100% {
  background-position: 1000px 0;
}
`;

export const ScoreLoader = styled.div`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 55px;
  height: 100px;
  background: radial-gradient(
    farthest-side,
    ${colors.deepGray} 0%,
    #c0c0c0 -54%,
    ${colors.deepGray} 75%
  );
  background: linear-gradient(
    90deg,
    ${colors.deepGray} 25%,
    #7b7b7b 50%,
    ${colors.deepGray} 75%
  );
  animation: ${skeletonLoading} 2s infinite;
  background-repeat: no-repeat;
  background-size: 166% 80%; /* Stretch horizontally */
  background-position: left center;
`;

const skeletonLoading2 = keyframes`
0% {
  background-position: -1800px 0;
}
100% {
  background-position: 1000px 0;
}
`;

export const SummaryLoader = styled.div`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 55px;
  height: 600px;
  background: radial-gradient(
    farthest-side,
    ${colors.deepGray} 0%,
    #c0c0c0 -54%,
    ${colors.deepGray} 75%
  );
  background: linear-gradient(
    90deg,
    ${colors.deepGray} 25%,
    #7b7b7b 50%,
    ${colors.deepGray} 75%
  );
  animation: ${skeletonLoading2} 2s infinite;
  background-repeat: no-repeat;
  background-size: 166% 80%; /* Stretch horizontally */
  background-position: left center;
`;
